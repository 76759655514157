import styled from 'styled-components'

export const ButtonContainer = styled.button`
    text-transform: uppercase;
    border: 2px solid var(--color-black);
    border-radius: 4px;
    box-shadow: 0px 2px 0 0 rgba(0, 0, 0, 0.3);
    padding: 0.6rem 1.2em;
    font: var(--font-dame);
    font-size: 2rem;
    color: inherit;
    background-color: transparent;

    &:hover {
        cursor: pointer;
        background-color: var(--color-black);
        color: ${(props: { hoverColor: string }) => props.hoverColor};
    }    
`