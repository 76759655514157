import Button from 'components/button/Button'
import { BodyText, Container, HeadLine, Modal } from './Style'

type AboutProps = {
    closeModal: () => void
}
const About = ({ closeModal }: AboutProps) => {

    const handleTapDonation = () => {
        const link = `https://www.buymeacoffee.com/fiddlestix`
        window.open(link, '_blank')
    }

    return (
        <Container onClick={closeModal}>
            <Modal onClick={(e) => e.stopPropagation()}>
                <HeadLine>
                    👉⌚
                    <br />
                    Y.K.W.T.I.I
                </HeadLine>
                <BodyText>
                    After witnessing Dame’s greatness night in and night out, we noticed there wasn’t a go-to source to
                    know if and when it’s Dame Time. So we set out to make it, but we’re not done yet. Enjoy this lil
                    thing we put together as we collectively watch Dame do his thing.
                </BodyText>
                <BodyText>
                    #YKWTII #dametime
                    <br />
                    <a href='https://twitter.com/isitdametime'>@isitdametime</a>
                </BodyText>
                <Button handleTap={handleTapDonation}>drop us some dollas</Button>
            </Modal>
        </Container>
    )
}

export default About
