import styled from 'styled-components'

export const Container = styled.main`
    position: absolute;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 100;
`

export const Modal = styled.div`
    width: 90%;
    max-width: 330px;
    border-radius: 1.2rem;
    background-color: var(--color-white);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem 1.3rem 2.4rem;
    color: var(--color-black);
    text-align: center;

    .button {
        margin-top: 0.7rem;
    }
`

export const HeadLine = styled.p`
    font: var(--font-semi-bold);
    font-size: 1.5rem;
    letter-spacing: 0.16em;
    text-transform: uppercase;
    line-height: 1.5;
    margin-bottom: 1.2rem;
`

export const BodyText = styled.p`
    font: var(--font-regular);
    color: #626262;
    font-size: 1.125rem;
    letter-spacing: 0.05em;
    margin-bottom: 1.2rem;
    color: var(--color-gray);

    a {
        font: var(--font-semi-bold);
        text-decoration: underline;
    }
`

export const FireElmo = styled.img`
    width: 100%;
    object-fit: cover;
    height: 10rem;
    margin-top: 0.5rem;
    margin-bottom: 1.2rem;
`

export const SpreadTheHype = styled(BodyText)`
    margin-top: 1.8rem;
    margin-bottom: 1rem;
    color: var(--color-black);
`

export const ShareButtonRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: -.2rem;
    margin-bottom: 1rem;
    position: relative;

    button {
        margin: 0 0.6rem;
    }
`

export const CopyMessageText = styled(BodyText)`
    position: relative;
    font-size: 1rem;
    font-size: 0.875ren;
    margin: .4rem 0 -1.6rem;
`
