import styled from 'styled-components'
import textureImage from '../../assets/images/jersey-pattern.png'

interface ContainerProps {
    bgColor: string;
    showTexture: boolean;
}
export const Container = styled.main<ContainerProps>`
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    font-size: 1.125rem;
    color: var(--color-black);
    background-color: ${(props) => props.bgColor};
    background-image: ${(props) => props.showTexture ? `url(${textureImage})` : 'none'};
    background-size: 500px 500px;
    background-repeat: repeat;
    background-position: center;
`

export const Column = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
`

export const EmptyTop = styled.div`
    flex-grow: 0.4;
`

export const HeadLine = styled.p`
    font: var(--font-semi-bold);
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.15em;
    user-select: none;
    font-size: clamp(1.125rem, 4vw, 1.5rem);
    color: ${(props: { color: string }) => props.color};
`

export const DameTimeWidget = styled.div`
    aspect-ratio: 16/9;
    position: relative;

    width: 100%;
    @media screen and (min-width: 600px) {
        width: clamp(600px, calc((100vw - 600px) * .25 + 600px), 740px);
    }
    transition: width 0.5s ease-in-out;
    margin: 3.5rem 0;

    & > * {
        position: absolute;
        width: 100%;
        height: 100%;    
    }
`

export const DameGif = styled.div`
    z-index: 1;
    background-image: url(${(props: { gif: string }) => props.gif});
    background-size: cover;
`

export const UnderText = styled.div`
    z-index: 0;    
    & > p {
        position: absolute;
    }
    & > p:first-child {
        top: -0.6em;
    }
    & > p:last-child {
        bottom: -0.6em;
    }
`

export const OverText = styled(UnderText)`
    z-index: 2;
    overflow: hidden;
    mix-blend-mode: difference;
`

export const BigText = styled(HeadLine)`
    width: 100%;
    position: absolute;
    font: var(--font-dame);
    font-size: ${(props: { isDameTime: boolean }) => props.isDameTime ? 'clamp(8.75rem, 37vw, 14rem)' : 'clamp(4.375rem, 20vw, 7.5rem)'};
    text-transform: uppercase;
    letter-spacing: 0;
    z-index: 10;
    user-select: none;
`

export const LFGContainer = styled.div`
    position: relative;
`

export const JerseyArt = styled.div`
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 1rem;
    background-image: ${(props: { bgImage: string }) => props.bgImage};
    background-size: cover;
    backgrounnd-repeat: repeat;
    z-index: 100;
    pointer-events: none;
`

export const JerseyTexture = styled.div`
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-image: url(${textureImage});
    background-size: 500px 500px;
    background-repeat: repeat;
    pointer-events: none;
`

interface FooterProps {
    color: string;
    extraPadding: boolean;
}
export const Footer = styled.footer<FooterProps>`
    flex-grow: 0.1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font: var(--font-medium);
    text-transform: uppercase;
    user-select: none;
    padding-bottom: ${(props) => props.extraPadding ? '2rem' : '1rem'};
    color: ${(props) => props.color};

    a {
        font-size: 1rem;
        letter-spacing: 0.07em;
        line-height: 1.6;
    }
    a: hover {
        cursor: pointer;
    }

    span {
        display: block;
        opacity: 0.5;
        font-size: 0.625rem;
        letter-spacing: 0.05em;
    }

`
