import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import isEqual from 'lodash/isEqual'

import { AppData } from 'data/data'
import processData from 'data/processData'
import MainView from 'components/mainview/MainView'

const REFRESH_INTERVAL_SECONDS = 8

const App = () => {
    const [appData, setAppData] = useState<AppData | null>(null)

    useEffect(() => {
        const fetchAppState = async () => {
            try {
                const res = await fetch(`data.json`)
                const { appState, schedule } = await res.json()
                const newAppData = processData(appState, schedule)

                setAppData((currentAppData) => {
                    // Only update state if newAppData is different from current appData
                    if (!isEqual(currentAppData, newAppData)) {
                        document.body.style.backgroundColor = newAppData.bgColor
                        document.body.className = newAppData.appState
                        return newAppData
                    }
                    return currentAppData
                })
            } catch (error) {
                console.log(error)
            }
        }

        const id = setInterval(() => {
            fetchAppState()
        }, REFRESH_INTERVAL_SECONDS * 1000)

        fetchAppState()

        return () => clearInterval(id)
    }, [])


    if (appData === null) {
        return <LoadingContainer>⌚</LoadingContainer>
    }
    return <MainView appData={appData} />
}

export default App

const LoadingContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #CFCFCF;
    height: 100%;
    width: 100%;
    font-size: 1.5rem;
`
