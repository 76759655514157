import cityJerseyImage from '../assets/images/jersey-city.png'
import chillGif from '../assets/gifs/dt_chilltime_01.gif'
import dame1Gif from '../assets/gifs/dt_dametime_01.gif'
import game1Gif from '../assets/gifs/dt_gametime_01.gif'
import rest1Gif from '../assets/gifs/dt_resttime_01.gif'
import tame1Gif from '../assets/gifs/dt_tametime_01.gif'
import prep1Gif from '../assets/gifs/dt_preptime_01.gif'
import tank1Gif from '../assets/gifs/dt_tanktime_01.gif'
import allNBA1Gif from '../assets/gifs/dt_all_nbatime_01.gif'
import tune1Gif from '../assets/gifs/dt_tunetime_01.gif'
import draft1Gif from '../assets/gifs/dt_drafttime_01.gif'
import trade1Gif from '../assets/gifs/dt_tradetime_01.gif'
import bucks1Gif from '../assets/gifs/dt_buckstime_01.gif'

const DAME_GIFS = [game1Gif]
export const gameGif =DAME_GIFS[ Math.floor(Math.random() * DAME_GIFS.length)]

export enum APP_STATE {
    PREP = 'prep',
    TAME = 'tame',
    REST = 'rest',
    TUNE = 'tune',
    TANK = 'tank',
    ALL_NBA = 'all-nba',
    DRAFT = 'draft',
    TRADE = 'trade',
    BUCKS = 'bucks',
    CHILL = 'chill',
    GAME = 'game',
    DAME = 'dame',
    DAME_EXTREME = 'dame-extreme',
}

export enum JERSEY {
    ASSOCIATION = 'Association',
    ICON = 'Icon',
    STATEMENT = 'Statement',
    CITY = 'City',
}

export type AppData = {
    appState: APP_STATE
    headlineUpperText: string
    headlineLowerText: string
    bigUpperText: string
    bigLowerText: string
    jersey: JERSEY,
    jerseyImage: string
    gif: string
    bgColor: string
    textColor: string
}

export const DEFAULT_APP_DATA: AppData = {
    headlineUpperText: 'Right now it’s',
    headlineLowerText: '',
    bigUpperText: 'Chill',
    bigLowerText: 'Time',
    gif: chillGif,
    bgColor: 'var(--color-white)',
    textColor: 'var(--color-black)',
    jerseyImage: 'transparent',
    jersey: JERSEY.ASSOCIATION,
    appState: APP_STATE.CHILL,
}

const DEFAULT_APP_STYLE = {
    bgColor: '#CFCFCF',
    textColor: 'var(--color-black)',
    bigTextColor: 'var(--color-white)',
    footerTextColor:'var(--color-transparent-black)',
}

export const STYLES_FOR_APP_STATE = {
    [APP_STATE.PREP]: {
        ...DEFAULT_APP_STYLE,
        gif: prep1Gif,
    },
    [APP_STATE.TAME]: {
        ...DEFAULT_APP_STYLE,
        gif: tame1Gif,
    },
    [APP_STATE.REST]: {
        ...DEFAULT_APP_STYLE,
        gif: rest1Gif,
    },
    [APP_STATE.TANK]: {
        ...DEFAULT_APP_STYLE,
        gif: tank1Gif,
    },
    [APP_STATE.ALL_NBA]: {
        ...DEFAULT_APP_STYLE,
        gif: allNBA1Gif,
    },
    [APP_STATE.DRAFT]: {
        ...DEFAULT_APP_STYLE,
        gif: draft1Gif,
    },
    [APP_STATE.TRADE]: {
        ...DEFAULT_APP_STYLE,
        gif: trade1Gif,
    },
    [APP_STATE.BUCKS]: {
        ...DEFAULT_APP_STYLE,
        gif: bucks1Gif,
    },
    [APP_STATE.CHILL]: {
        ...DEFAULT_APP_STYLE,
        gif: chillGif,
    },
    [APP_STATE.DAME]: {
        bgColor: 'var(--color-red)',
        textColor: 'var(--color-black)',
        gif: dame1Gif,
    },
    [APP_STATE.DAME_EXTREME]: {
        bgColor: 'var(--color-red)',
        textColor: 'var(--color-black)',
    },
}

export const STYLES_FOR_ACTIVE_GAME = {
    [JERSEY.ASSOCIATION]: {
        bgColor: 'var(--color-white)',
        textColor: 'var(--color-green)',
        jerseyImage: 'linear-gradient(to top, var(--color-green), var(--color-green))',
        jersey: JERSEY.ASSOCIATION,
    },
    [JERSEY.ICON]: {
        bgColor: 'var(--color-green)',
        textColor: 'var(--color-cream)',
        jerseyImage: 'linear-gradient(to top, var(--color-cream), var(--color-cream))',
        jersey: JERSEY.ICON,
    },
    [JERSEY.STATEMENT]: {
        bgColor: 'var(--color-black)',
        textColor: 'var(--color-cream)',
        jerseyImage: 'linear-gradient(to top, var(--color-light-blue) 0%, var(--color-light-blue) 50%, var(--color-cream) 50.1%, var(--color-cream) 100%)',
        jersey: JERSEY.STATEMENT,
    },
    [JERSEY.CITY]: {
        bgColor: 'var(--color-blue)',
        textColor: 'var(--color-cream)',
        jerseyImage: 'linear-gradient(to top, var(--color-light-green), var(--color-light-green))',
        jersey: JERSEY.CITY,
    },
}
