import React from 'react'
import { Container } from './Style'

const Antler = () => {
    return (
        <svg width="192" height="437" viewBox="0 0 192 437" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M217.265 185.083C166.579 85.7947 96.1436 60.2597 80.4589 55.6659C80.0543 55.5419 79.8193 56.1111 80.1929 56.3094C114.952 74.7813 154.099 123.068 186.239 179.754C169.249 165.288 157.624 160.818 142.112 153.978C85.5913 80.8117 31.4519 53.0822 0.460694 50.7457C0.0606131 50.7067 -0.0568807 51.2722 0.314397 51.428C39.6002 67.0163 80.815 122.39 92.297 141.704C55.4684 137.21 43.8781 146.059 41.3372 148.634C41.1069 148.866 41.2902 149.253 41.6168 149.228C46.6815 148.869 67.705 147.359 110.904 168.635C133.174 202.756 148.635 240.474 161.809 278.82C174.732 316.454 187.475 354.133 200.332 391.799C201.041 393.86 201.739 395.925 202.45 397.996C202.45 397.996 218.802 397.772 235.665 400.48C236.006 400.537 236.357 400.591 236.698 400.648C254.238 334.975 257.073 263.088 217.255 185.086L217.265 185.083ZM217.29 334.028C217.29 334.028 193.123 236.941 162.252 194.577C166.895 198.042 178.331 203.51 182.97 207.592C228.141 247.479 217.29 334.028 217.29 334.028Z" fill="#EEE1C6"/>
        </svg>
    )
}

const Antlers = () => {
    return (
        <Container>
            <Antler />
            <Antler />
        </Container>
    )
}

export default Antlers
