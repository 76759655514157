import styled from 'styled-components'

export const Container = styled.div`
    position: fixed;
    top: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;

    & > svg {
        width: clamp(8rem, 32vmin, 12rem);
        height: auto;
        margin-right: -.2rem;
    }

    & > svg:first-child {
        transform: scaleX(-1);
        margin-right: 0;
        margin-left: -.2rem;
    }
`