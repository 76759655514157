import { useClipboard } from 'use-clipboard-copy'

import Button from 'components/button/Button'
import { BodyText, Container, FireElmo, HeadLine, Modal, CopyMessageText } from './Style'
import fireElmoGif from '../../assets/gifs/fire-elmo.gif'

type AboutProps = {
    closeModal: () => void
}
const LFG = ({ closeModal }: AboutProps) => {
    const clipboard = useClipboard({
        copiedTimeout: 2500 // timeout duration in milliseconds
    })

    const handleTapShare = () => {
        if (navigator.share) {
            navigator.share({
                title: 'Let’s Fucking Go!',
                text: 'ATTENTION: This isn’t a drill. It’s time; Dame time! 👉🏼⌚',
                url: window.location.href,
            })
            .then(() => console.log('Successful share'))
            .catch((error) => console.log('Error sharing', error));
        } else {
            console.log('copied url to clipboard')
            const link = window.location.origin
            clipboard.copy(link)    
            }
    }
    console.log(clipboard)

    return (
        <Container onClick={closeModal}>
            <Modal onClick={(e) => e.stopPropagation()}>
                <HeadLine>
                    😤😤😤
                    <br />
                    Let’s Fucking Go!
                </HeadLine>
                <BodyText>
                    ATTENTION:<br/>
                    This isn’t a drill. It’s time; Dame time!<br/>
                    #YKWTII #dametime
                </BodyText>
                <FireElmo src={fireElmoGif} />
                <Button handleTap={handleTapShare}>Spread the Hype!</Button>
                {clipboard.copied && 
                    <CopyMessageText>Copied link. Paste away!</CopyMessageText> 
                }
            </Modal>
        </Container>
    )
}

export default LFG
