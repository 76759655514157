import { useEffect, useMemo, useState } from 'react'
import Particles, { initParticlesEngine } from '@tsparticles/react'
import { Container, IOptions, ISourceOptions, Move, MoveDirection, OutMode, ParticlesOptions, RecursivePartial } from '@tsparticles/engine'
import { loadFull } from 'tsparticles'
import { loadEmittersPlugin } from '@tsparticles/plugin-emitters'

const particles: RecursivePartial<IOptions> = {
    move: {
        angle: 15,
        direction: 'top' as MoveDirection,
        enable: true,
        outModes: {
            default: 'destroy' as OutMode,
        },
        speed: {
            min: 15,
            max: 20,
        },
    },
    shape: {
        options: {
            character: [{
                value: '🔥',
            },{
                value: '🔥',
            },{
                value: '🔥',
            },{
                value: '⌚',
            },]
        },
        type: 'character',
    },
    size: {
        value: 15,
    },
}

const FireParticles = () => {
    const [init, setInit] = useState(false)

    useEffect(() => {
        initParticlesEngine(async engine => {
            await loadFull(engine)
            await loadEmittersPlugin(engine)
        }).then(() => {
            setInit(true)
        })
    }, [])

    const options: RecursivePartial<IOptions> = useMemo(
        () => ({
            background: {
                color: {
                    value: 'transparent',
                },
            },
            fpsLimit: 120,
            interactivity: {
                events: {
                    onClick: {
                        enable: true,
                        mode: 'push',
                    },
                },
                modes: {
                    push: {
                        quantity: 10,
                    },
                },
            },
            particles: particles,
            emitters: [{
                direction: 'top' as MoveDirection,
                rate: {
                    quantity: 2,
                },
                size: {
                    width: 10,
                    height: 0,
                },
                position: {
                    x: 5,
                    y: 100,
                },
                particles: particles,
            },
            {
                direction: 'top' as MoveDirection,
                rate: {
                    quantity: 2,
                },
                size: {
                    width: 10,
                    height: 0,
                },
                position: {
                    x: 95,
                    y: 100,
                },
                particles: particles,
            },],
            detectRetina: false,
        }),
        []
    )

    if (init) {
        return <Particles id='tsparticles' options={options} />
    }

    return <></>
}

export default FireParticles
