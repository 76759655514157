import { AppData, APP_STATE, JERSEY } from '../../data/data'
import { BigText, Container, DameTimeWidget, Footer, DameGif, HeadLine, JerseyArt, LFGContainer, UnderText, OverText, EmptyTop, Column } from './Style'

import { useState } from 'react'
import About from 'components/modal/About'
import LFG from 'components/modal/LFG'
import Button from 'components/button/Button'
import Antlers from 'components/antler/Antlers'
import FireParticles from 'components/particles/FireParticles'

type MainViewProps = {
    appData: AppData
}
const MainView = ({ appData }: MainViewProps) => {
    const {
        headlineLowerText,
        headlineUpperText,
        bigLowerText,
        bigUpperText,
        bgColor,
        textColor,
        gif,
        jerseyImage,
        jersey,
        appState,
    } = appData

    const [showAbout, setShowAbout] = useState(false)
    const [showLFG, setShowLFG] = useState(false)

    const isDameTime = appState === APP_STATE.DAME || appState === APP_STATE.DAME_EXTREME
    const showTexture = appState === APP_STATE.GAME && jersey === JERSEY.CITY
    const showAntlers = appState === APP_STATE.GAME && jersey === JERSEY.STATEMENT

    return (
        <Container bgColor={bgColor} showTexture={showTexture}>
            {showAntlers && <Antlers />}
            {isDameTime && <FireParticles />}
            <Column>
                <EmptyTop />
                <HeadLine color={textColor}>{headlineUpperText}</HeadLine>
                <DameTimeWidget>
                    <UnderText>
                        <BigText isDameTime={isDameTime} color={textColor}>
                            {bigUpperText}
                        </BigText>
                        <BigText isDameTime={isDameTime} color={textColor}>
                            {bigLowerText}
                        </BigText>
                    </UnderText>
                    <DameGif gif={gif} />
                    <OverText>
                        <BigText isDameTime={isDameTime} color='white'>
                            {bigUpperText}
                        </BigText>
                        <BigText isDameTime={isDameTime} color='white'>
                            {bigLowerText}
                        </BigText>
                    </OverText>
                </DameTimeWidget>
                {!isDameTime && <HeadLine 
                    color={textColor} 
                    dangerouslySetInnerHTML={{ __html: headlineLowerText}} />
                }
                {isDameTime && 
                    <LFGContainer>
                        <Button hoverColor='var(--color-red)' handleTap={() => setShowLFG(true)}>
                            Let’s Fucking Go!
                        </Button>
                    </LFGContainer>
                }
                <Footer color={textColor} extraPadding={appState === APP_STATE.GAME}>
                    <a onClick={() => setShowAbout(true)}>Story Time</a>
                    <span>©2024 Fiddlestix</span>
                </Footer>
            </Column>
            {appState === APP_STATE.GAME && <JerseyArt bgImage={jerseyImage} />}
            {showAbout && <About closeModal={() => setShowAbout(false)} />}
            {showLFG && <LFG closeModal={() => setShowLFG(false)} />}
        </Container>
    )
}

export default MainView
