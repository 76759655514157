import { parse, format, addHours } from 'date-fns'

import { APP_STATE, JERSEY, AppData, STYLES_FOR_APP_STATE, STYLES_FOR_ACTIVE_GAME, DEFAULT_APP_DATA, gameGif } from './data'

const GAME_DURATION_HOURS = 3

type GameData = {
    date: Date
    opponent: string
    jersey: JERSEY
}

const processData = (appState: any, schedule: any): AppData => {
    const appData: AppData = {...DEFAULT_APP_DATA}

    if (schedule) {
        const games: GameData[] = schedule.map((row: any) => {
            const dateRaw = row.date as string // in the form of M/D/YYYY
            const timeRaw = row.time as string // in the form of H:MM am/pm
            let gameDate = parse(dateRaw, 'M/d/yyyy', new Date())
            gameDate = parse(timeRaw, 'h:mm a', gameDate)

            let jersey = (row.jersey as JERSEY) || JERSEY.ASSOCIATION

            return {
                date: gameDate,
                opponent: row.opponent,
                jersey: jersey,
            }
        })

        const now = new Date()
        const nextGame = games.find(game => addHours(game.date, GAME_DURATION_HOURS) > now)

        if (nextGame) {
            if (now > nextGame.date) {
                document.title = 'Game Time'
                appData.bigUpperText = 'Game'
                appData.headlineLowerText = `currently playing ${nextGame.opponent}`
                appData.appState = APP_STATE.GAME
                Object.assign(appData, STYLES_FOR_ACTIVE_GAME[nextGame.jersey])
                appData.gif = gameGif
            } else {
                const gameDateString = format(nextGame.date, 'MMM.d')
                document.title = 'Chill Time'
                appData.bigUpperText = 'Chill'
                appData.headlineLowerText = `next game is ${gameDateString} vs ${nextGame.opponent}`
                appData.appState = APP_STATE.CHILL
                Object.assign(appData, STYLES_FOR_APP_STATE[APP_STATE.CHILL])
            }
        }
    }
    
    // check for debug override value to preview other app states
    const url = new URL(window.location.href)
    const params = new URLSearchParams(url.search)
    if (params.has('debug')) {
        const debugValue = params.get('debug')
        console.log('debug value:', debugValue)
        if (debugValue && Object.values(JERSEY).includes(debugValue as JERSEY)) {
            console.log('jersey detected. setting game state.')
            document.title = 'Game Time'
            appData.bigUpperText = 'Game'
            appData.headlineLowerText = `currently playing XXX`
            appData.appState = APP_STATE.GAME
            Object.assign(appData, STYLES_FOR_ACTIVE_GAME[debugValue as JERSEY])
            appData.gif = gameGif
            appState = 'default'
        } else {
            appState = debugValue
        }
    }

    if (appState) {
        console.log('appState:', appState)
        switch (appState) {
            case APP_STATE.DAME:
                document.title = 'Dame Time!'
                appData.appState = APP_STATE.DAME
                appData.bigUpperText = 'Dame'
                appData.bigLowerText = 'Time!'
                Object.assign(appData, STYLES_FOR_APP_STATE[APP_STATE.DAME])
                break
            case APP_STATE.DAME_EXTREME:
                document.title = 'Dame Time Extreme!'
                appData.appState = APP_STATE.DAME_EXTREME
                appData.bigUpperText = 'Dame'
                appData.bigLowerText = 'Time!'
                Object.assign(appData, STYLES_FOR_APP_STATE[APP_STATE.DAME_EXTREME])
                break
            case APP_STATE.PREP:
                document.title = 'Prep Time'
                appData.appState = APP_STATE.PREP
                appData.bigUpperText = 'Prep'
                appData.headlineLowerText = `Playoffs start Apr. 15`
                Object.assign(appData, STYLES_FOR_APP_STATE[APP_STATE.PREP])
                break
            case APP_STATE.TAME:
                document.title = 'Tame Time'
                appData.appState = APP_STATE.TAME
                appData.bigUpperText = 'Tame'
                appData.headlineLowerText = `Season starts Oct. 24`
                Object.assign(appData, STYLES_FOR_APP_STATE[APP_STATE.TAME])
                break
            case APP_STATE.REST:
                document.title = 'Rest Time'
                appData.appState = APP_STATE.REST
                appData.bigUpperText = 'Rest'
                appData.headlineLowerText = 'GET WELL SOON <3'
                Object.assign(appData, STYLES_FOR_APP_STATE[APP_STATE.REST])
                break
            case APP_STATE.TANK:
                document.title = 'Tank Time'
                appData.appState = APP_STATE.TANK
                appData.bigUpperText = 'Tank'
                appData.headlineLowerText = 'Shit.'
                Object.assign(appData, STYLES_FOR_APP_STATE[APP_STATE.TANK])
                break
            case APP_STATE.ALL_NBA:
                document.title = 'All-NBA Team Time'
                appData.appState = APP_STATE.ALL_NBA
                appData.bigUpperText = 'All Team'
                appData.headlineLowerText = 'ALL-NBA 7 TIMES AND COUNTING<br /> CONGRATS, DAME!'
                Object.assign(appData, STYLES_FOR_APP_STATE[APP_STATE.ALL_NBA])
                break
            case APP_STATE.DRAFT:
                document.title = 'Draft Time'
                appData.appState = APP_STATE.DRAFT
                appData.bigUpperText = 'Draft'
                appData.headlineLowerText = 'Refresh, refresh, refresh…'
                Object.assign(appData, STYLES_FOR_APP_STATE[APP_STATE.DRAFT])
                break
            case APP_STATE.TRADE:
                document.title = 'Trade Time'
                appData.appState = APP_STATE.TRADE
                appData.bigUpperText = 'Trade'
                appData.headlineLowerText = 'The next episode'
                Object.assign(appData, STYLES_FOR_APP_STATE[APP_STATE.TRADE])
                break
            case APP_STATE.BUCKS:
                document.title = 'Bucks Time'
                appData.appState = APP_STATE.TRADE
                appData.bigUpperText = 'Bucks'
                appData.headlineLowerText = 'A new chapter begins.'
                Object.assign(appData, STYLES_FOR_APP_STATE[APP_STATE.BUCKS])
                break
            case 'default':
            default:
                break
        }
    }

    return appData
}

export default processData
