// simple button component
import React from 'react'
import { ButtonContainer } from './Style'

type ButtonProps = {
    children: any
    hoverColor?: string
    handleTap: () => void
}
const Button = ({ children, handleTap, hoverColor='var(--color-white)' }: ButtonProps) => {
    return <ButtonContainer hoverColor={hoverColor} onClick={handleTap} className='button'>{children}</ButtonContainer>
}

export default Button
